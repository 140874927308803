// NOTE: This mixin assumes there are multiple giveaways and they are
// all in sync, meaning they start and end at the same time.

import fetchGiveawaysMixin from '../fetch-giveaways'
import gaEventMixin from '../ga_event'

export default {
  mixins: [fetchGiveawaysMixin, gaEventMixin],
  data() {
    return {
      pageMetaTitle: '',
      isLoading: true,
      isUnderway: false,
      isEndedView: false,
      isError: false,
      idTokens: [],
      giveawayList: [],
      activeGiveawayInterval: null
    }
  },
  watch: {
    isEndedView() {
      if (this.isEndedView) {
        clearInterval(this.activeGiveawayInterval)
      }
    }
  },
  async created() {
    try {
      const giveawayList = await this.fetchGiveawaysByIdTokens(this.idTokens)
      this.giveawayList = this.sortGiveaways(giveawayList)
      this.activeGiveawayCheck()
      this.activeGiveawayInterval = setInterval(this.activeGiveawayCheck, 1000)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      this.isError = true
    } finally {
      this.isLoading = false
    }
  },
  beforeDestroy() {
    clearInterval(this.activeGiveawayInterval)
  },
  methods: {
    jumpTo(id) {
      this.trackEvent(`jump_to_${id}`)
      this.$helpers.scrollToId(id)
    },
    trackGiveawayClick(idToken) {
      this.trackEvent(`to_giveaway__${idToken}`)
    },
    getGiveawayByIdToken(idToken) {
      return this.giveawayList.find((g) => g.idToken === idToken)
    },
    activeGiveawayCheck() {
      this.isUnderway = this.giveawayList.some((g) => g.isActive || g.isEnded)
      this.isEndedView = this.giveawayList.every((g) => g.isEnded)
    },
    sortGiveaways(giveawayList) {
      return giveawayList.sort((a, b) => {
        if (a.startTime === b.startTime) {
          return a.title > b.title ? 1 : -1
        }

        return a.startTime > b.startTime ? 1 : -1
      })
    },
    refreshPage() {
      window.location.reload()
    }
  },
  metaInfo() {
    return {
      title: this.pageMetaTitle,
      titleTemplate: '%s | Surf Giveaways'
    }
  }
}
