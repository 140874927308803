<template lang="pug">
countdown(
  :countdown='startsIn',
  :countdown-color='countdownColor',
  :label='label',
  :label-color='labelColor',
  @finished='isStarted = true'
)
</template>
<script>
import Countdown from './Countdown'

export default {
  data() {
    return {
      isStarted: false,
      startsIn: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      startsInInterval: null
    }
  },
  props: {
    giveaway: {
      type: Object,
      required: true
    },
    countdownColor: {
      type: String,
      required: false,
      default: 'soft-purple'
    },
    label: {
      type: String,
      required: false,
      default: 'Ends In'
    },
    labelColor: {
      type: String,
      required: false,
      default: 'white'
    }
  },
  watch: {
    isStarted() {
      if (this.isStarted) {
        this.$emit('started', this.giveaway)
        clearInterval(this.startsInInterval)
      }
    }
  },
  created() {
    this.getFormattedStartsIn()
    this.startsInInterval = setInterval(this.getFormattedStartsIn, 1000)
  },
  beforeDestroy() {
    clearInterval(this.startsInInterval)
  },
  methods: {
    getFormattedStartsIn() {
      this.startsIn = this.giveaway.counterFormattedStartsIn

      if (this.giveaway.startsIn <= 0) {
        this.isStarted = true
      }
    }
  },
  components: {
    Countdown
  }
}
</script>
