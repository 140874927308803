<template lang="pug">
.countdown.d-inline-flex.align-items-center.justify-content-center(aria-label='Countdown')
  span.countdown__label.uppercase.fw-bolder.pr-3.pr-sm-2(v-if='label', :class='labelClasses') {{ label }}
  .countdown__counter.d-flex.align-items-center.fw-bolder(:class='counterClasses')
    .countdown__counter-timeslot.mr-1 {{ isFinished ? 0 : countdown.days }}D<span class='sr-only'>ays</span>
    .countdown__counter-timeslot.mr-1 {{ isFinished ? 0 : countdown.hours }}H<span class='sr-only'>ours</span>
    .countdown__counter-timeslot.mr-1 {{ isFinished ? 0 : countdown.minutes }}M<span class='sr-only'>inutes</span>
    .countdown__counter-timeslot {{ isFinished ? 0 : countdown.seconds }}S<span class='sr-only'>econds</span>
</template>
<script>
export default {
  data() {
    return {
      isFinished: false
    }
  },
  props: {
    countdown: {
      type: Object,
      required: true
      /*
        format: {
          days: 1,
          hours: 1,
          minutes: 1,
          seconds: 1
        }
      */
    },
    countdownColor: {
      type: String,
      required: false,
      default: 'soft-purple'
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    labelColor: {
      type: String,
      required: false,
      default: 'white'
    }
  },
  computed: {
    labelClasses() {
      return `color-${this.labelColor}`
    },
    counterClasses() {
      return `color-${this.countdownColor}`
    }
  },
  watch: {
    countdown: {
      handler(countdown) {
        const { days, hours, minutes, seconds } = countdown
        if (days === 0 && hours === 0 && (minutes === 0 || minutes === '00') && (seconds === 0 || seconds === '00')) {
          this.isFinished = true
          this.$emit('finished')
        }
      },
      deep: true
    }
  }
}
</script>
