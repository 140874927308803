<template lang="pug">
countdown(
  :countdown='endsIn',
  :countdown-color='countdownColor',
  :label='label',
  :label-color='labelColor',
  @finished='isEnded = true'
)
</template>
<script>
import Countdown from './Countdown'

export default {
  data() {
    return {
      isEnded: false,
      endsIn: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      endsInterval: null
    }
  },
  props: {
    giveaway: {
      type: Object,
      required: true
    },
    countdownColor: {
      type: String,
      required: false,
      default: 'soft-purple'
    },
    label: {
      type: String,
      required: false,
      default: 'Ends In'
    },
    labelColor: {
      type: String,
      required: false,
      default: 'white'
    }
  },
  watch: {
    isEnded() {
      if (this.isEnded) {
        this.$emit('ended', this.giveaway)
        clearInterval(this.endsInterval)
      }
    }
  },
  created() {
    this.getFormattedEndsIn()
    this.endsInterval = setInterval(this.getFormattedEndsIn, 1000)
  },
  beforeDestroy() {
    clearInterval(this.endsInterval)
  },
  methods: {
    getFormattedEndsIn() {
      this.endsIn = this.giveaway.counterFormattedEndsIn

      if (this.giveaway.endsIn <= 0) {
        this.isEnded = true
      }
    }
  },
  components: {
    Countdown
  }
}
</script>
