<template lang="pug">
page.itt(:show-header-links='false', :ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')
  //- Content
  template(slot='content')
    //- Banner
    page-section.itt-section.itt-section--banner(:fullWidth='true', align='center')
      template(slot='content')
        .banner-img

    //- Main
    page-section#main.itt-section.itt-section--enter.page-pt-md-alt.page-pb-md(align='center')
      template(slot='content')
        //- Loading
        loading-icon.page-mt-lg-alt.page-mb-xxl(v-if='isLoading', size='lg', color='itt-yellow')

        //- Ended View
        template(v-else-if='isEndedView')
          h1.h2.fw-bold Thanks to everyone who entered!
          .fs-md.page-mb-md-alt View winners

          //- Giveaway list
          giveaway-list(
            :list='giveawayList',
            :show-prizes='false',
            :show-dates='false',
            :show-state='false',
            :show-action-button='true',
            action-button-variant='itt-yellow',
            title-size='md-alt',
            title-align='center',
            tile-size='full',
            tile-size-sm='half',
            tile-size-lg='lg',
            tile-classes='sm:page-px-sm',
            :ga-event-category='gaEventCategory',
            :ga-event-label='gaEventLabel'
          )

        //- All other scenarios
        template(v-else)
          .text-center.mx-auto.max-w-md.page-mb-md
            h1.h2.fw-bold Partner with your Player 2 in this giveaway for your chance to win 1 of 2 custom It Takes Two <span class="text-nowrap">console bundles!</span>

          //- Error
          template(v-if='isError') Sorry, there was an error, please try refreshing the page.

          //- No Giveaways
          template(v-else-if='!giveawayList.length') Coming Soon

          //- Has Giveaways
          template(v-else)
            //- Countdown
            component.bg-color-itt-yellow.px-3.py-1.page-mt-sm.page-mb-md-alt.rounded-sm(
              :is='countdownTemplate',
              :giveaway='giveawayList[0]',
              :label='isUnderway ? "Ends In:" : "Starts In:"',
              label-color='itt-red',
              countdown-color='itt-red'
            )

            //- Giveaway list
            giveaway-list(
              :list='giveawayList',
              :show-prizes='false',
              :show-dates='false',
              :show-state='false',
              :show-action-button='true',
              action-button-variant='itt-yellow',
              title-size='md-alt',
              title-align='center',
              tile-size='full',
              tile-size-sm='half',
              tile-size-lg='lg',
              tile-classes='sm:page-px-sm',
              :ga-event-category='gaEventCategory',
              :ga-event-label='gaEventLabel'
            )

  //- Footer
  template(slot='footer')
    base-footer(:ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')
      template(slot='custom-image')
        .mt-3.mt-sm-0
          img.mr-2(:src='esrbRatingSrc', height='60', alt='ESRB Rating')
          img(:src='pegiRatingSrc', height='60', alt='PEGI Rating')
</template>
<script>
import GiveawayStartsIn from '../components/GiveawayStartsIn'
import GiveawayEndsIn from '../components/GiveawayEndsIn'
import BaseFooter from '../components/BaseFooter'
import multipleGiveawaysInSyncMixin from '../mixins/base/multiple-giveaways-in-sync'

export default {
  name: 'ItTakesTwo',
  mixins: [multipleGiveawaysInSyncMixin],
  data() {
    return {
      pageMetaTitle: 'It Takes Two',
      idTokens: ['mDJLGHU', 'yfA6TKC'],
      esrbRatingSrc: require('../assets/themes/it-takes-two/images/esrb-teen-rating.svg'),
      pegiRatingSrc: require('../assets/themes/it-takes-two/images/pegi-12-rating.png')
    }
  },
  computed: {
    countdownTemplate() {
      return this.isUnderway ? GiveawayEndsIn : GiveawayStartsIn
    }
  },
  methods: {
    // Override from 'multipleGiveawaysInSyncMixin'
    sortGiveaways(giveawayList) {
      return giveawayList.sort((a, b) => {
        return a.title < b.title ? 1 : -1
      })
    }
  },
  components: {
    GiveawayStartsIn,
    GiveawayEndsIn,
    BaseFooter
  }
}
</script>
<style lang="scss" src="../assets/themes/it-takes-two/scss/main.scss" />
